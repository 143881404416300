<template>
  <div>
    <div
      id="carousel-example"
      class="carousel slide"
      data-ride="carousel"
    >

      <div class="carousel-inner">
        <div class="item __item active">
          <div class="overlay"></div>
          <img :src="userAvatar ? userAvatar : require('../assets/img/default-img.jpg')" />
        </div>
        <!-- <div class="item __item">
          <div class="overlay"></div>
          <img src="../assets/img/default-img.jpg" />
        </div> -->
      </div>
    </div>
    <div v-if="loggedInUserSlug !== routeSlug">
      <router-link
        class="btn profiledetail__book"
        style="width: 100% !important"
        :to="`/${this.$route.params.userSlug? this.$route.params.userSlug: this.$route.params.companySlug}/book-work?type=${this.$route.params.userSlug ? 'user':'company'}`"
      >
        Book {{ userName }}
      </router-link>
    </div> 
  </div>
</template>

<script>

export default {
  name: "ProfileCarousel",
  props: ['user', 'slug', 'loggedInUser'],
  data() {
    return {
      userAvatar: null,
      userName: '',
      loggedInUserSlug: '',
      routeSlug: ''
    }
  },
  watch: {
    user(next, prev) {
      if (next && next.type !== 'company') {
        this.userAvatar = next.avatar;
        this.userName = next.fullName.split` `[0]
      } else {
        this.userAvatar = next.company.logo;
      }
    }
  },
  created() {
    this.userName = this.user.fullName.split` `[0]
    if (this.user && this.user.type !== 'company') {
      this.userAvatar = this.user.avatar;
      this.loggedInUserSlug = this.loggedInUser.slug;
      this.routeSlug = this.$route.params.userSlug;
    } else {
      this.userAvatar = this.user.company.logo;
      this.loggedInUserSlug = this.loggedInUser.company.slug;
      this.routeSlug = this.$route.params.companySlug;
    }
  }
};
</script>

<style>
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #080d1524;
  opacity: 0.7;
  border-radius: 5px;
}

.__item img {
  border-radius: 5px;
  height: 390px !important;
}

.carousel .__item {
  border-radius: 5px;
}

.__arro {
  margin-top: 12rem;
}

.carousel {
  border-radius: 5px;
}

.carousel .img-fluid {
  border-radius: 5px;
  height: 390px;
}

.carousel-indicators li {
  border-radius: 50%;
  width: 11px;
  height: 11px;
}

.profiledetail__book, .profiledetail_book1 {
  font-size: 20px;
  line-height: 114%;
  font-weight: 700;
  border-radius: 5px;
  padding: 11px 18px;
  transition: all .4s ease-in;
  margin: 20px 5px 5px 0;
}
</style>
