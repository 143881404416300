<template>
  <div>
    <topHeader :userData="user" />
    <section style="paddingTop: 2rem">
		<div class="container">
			<div class="profiledetail">
				<div class="row">
					<div class="col-xl-4 col-lg-4 col-md-4 col-sm-5"></div>
					<div class="col-xl-8 col-lg-8 col-md-8 col-sm-7">
						<div class="__movleft" v-if="companyData">
							<p class="profiledetail__name">{{ companyData.company.name }}</p>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-xl-4 col-lg-4 col-md-4 col-sm-5">
						<div class="__fixedimg">
              <template v-if="companyData">
							  <profileCarousel
                  :user="companyData"
                  :loggedInUser="user"
                  :userSlug="this.$route.params.userSlug"
                />
              </template>

              <template v-if="socialMedias">
                  <hr />
                  <div class="row">
                    <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
                      <a :href="`${companyData.company.twitter_url}`" target="_blank" v-if="companyData.company.twitter_url">
                        <img src="../assets/img/twitter.png" class="__sociaimgs"/>
                      </a>
                      <a :href="`${companyData.company.instagram_url}`" target="_blank" v-if="companyData.company.instagram_url">
                        <img src="../assets/img/instagram.png" class="__sociaimgs" />
                      </a>
                      <a :href="`${companyData.company.linkedin_url}`" target="_blank" v-if="companyData.company.linkedin_url">
                        <img src="../assets/img/linkedin.png" class="__sociaimgs" />
                      </a>
                      <a :href="`${companyData.company.facebook_url}`" target="_blank" v-if="companyData.company.facebook_url">
                        <img src="../assets/img/facebook.png" class="__sociaimgs"/>
                      </a>
                    </div>
                  </div>
                  <hr />
                </template>
						</div>
					</div>
					<div class="col-xl-8 col-lg-8 col-md-8 col-sm-7">
						<div class="__movleft">
							<p class="profiledetail__bio">Awards</p>
							<hr>
							<div class="profiledetail__offical">
                <template v-if="awards.length > 0">
                  <template v-for="(award, index) in awards">
                    <p class="profiledetail__offical-model" :key="index"><img src="../assets/img/ellipse.png"> {{ award }}</p>
                  </template>
                </template>
							</div>

							<p class="profiledetail__bio">Company’s Description</p>
							<p class="profiledetail__biodetail">{{ companyData.company.about }}</p>
							<hr>
							<p class="profiledetail__bio">Expertises</p>
							<hr>
              <div class="__emptyboxdow" style="backgroundColor: white">
                <template v-if="companyData">
                  <p class="__emptyboxdow-pr" v-if="!companyData.company.expertise">
                    You have not added any expertises.
                  </p>
                  <div v-if="companyData.company.expertise">
                    <template
                      v-for="(exp, index) of companyData.company.expertise.split(
                        ','
                      )"
                    >
                      <p
                        class="custom-chips"
                        style="justifyContent: center;"
                        :key="index"
                      >
                        {{ exp }}
                      </p>
                    </template>
                  </div>
                </template>
              </div>
						</div>
					</div>
				</div>

				<div class="__spacet7p"></div>
			</div>
		</div>
	</section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import UserMixin from "../mixins/usersMixins";

import topHeader from "../components/topHeader.vue";
import profileCarousel from "../components/profileCarousel";
import { errorHandler } from "../../utils/helpers";

export default {
  mixins: [UserMixin],
  components: {
    topHeader,
    profileCarousel
  },
  data() {
    return {
      companyData: null,
      slug: "",
      awards: []
    };
  },
  computed: {
    ...mapGetters([
      "mentorshipSessions",
      "company",
      "user"
    ]),
    socialMedias() {
      if(this.companyData) {
        return ( 
          this.companyData.company.facebook_url
          || this.companyData.company.twitter_url 
          || this.companyData.company.instagram_url
          || this.companyData.company.linkedin_url
        );
      }
    }
  },
  methods: mapActions([
    "fetchSessions",
    "fetchSingleUser",
    "handleFetchCompany"
  ]),
  async mounted() {
    const { companySlug } = this.$route.params;
    this.slug = companySlug;

    try {
      this.companyData = await this.handleFetchCompany(companySlug);

      if(this.companyData.company.awards) {
        this.awards = this.companyData.company.awards.split(',');
      }
    } catch (error) {
      errorHandler(error, this.$toast);
    }
  }
};
</script>

<style lang="scss">
.custom-chips {
  min-width: 94.21px;
  height: 31.21px;
  margin-top: 0;
  margin-bottom: 8px;
  background: #e5ebf5;
  padding: 5px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: space-between;
  font-size: 14px;
  color: #2357bb;
  align-items: center;
  margin-right: 10px;
  & > img {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    cursor: pointer;
  }
}
</style>
